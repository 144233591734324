.textContainer{
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url("../../../assets/images/banner/ship.jpg");
    
    width: 100%;
    background-repeat: no-repeat;
    object-fit: contain;
    /* background-position: center; */
    background-attachment: fixed;
}
.texts{
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}