.serviceContainer{
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(../../../assets/images/banner/bnr5.jpg);
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
}
.serviceText{
    height: 300px;
    display: flex;
    align-items: flex-end;
}