.contactBanner{
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(../../../assets/images/banner/bnr3.jpg);
    /* height: 100vh; */
    background-size: cover;
    background-position: center;
}
.contactText{
    /* height: 100vh; */
    
}
.contact{
    /* border-color: hsla(0,0%,100%,.1);
    background-color: hsla(0,0%,100%,.05);
    border-radius: 8px; */
    color: #fff;
    /* padding: 35px; */
    /* padding: 35px 0 0 15px; */
}