.bannerContainer{
    background-image: linear-gradient(rgba(0,0,0, 0.5), rgba(0,0, 0, 0.5)),
    url("../../../assets/images/banner/homeBanner.jpg");
    background-size: cover;
    height: 100vh;
}
.bannerText{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: white;
}