.teamBanner{
    background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("../../assets/images/banner/bnr6.jpg");
    background-repeat: no-repeat;
    object-fit: contain;
    height: 50vh;
    width: 100%;
}
.teamContent{
    height: 50vh;
    color: white;
}