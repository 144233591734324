.fleetContainer{
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(../../../assets/images/banner/aboutimg.jpg);
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
}
.fleetText{
    height: 300px;
    display: flex;
    align-items: flex-end;
}