.workingContainer{
    background-image: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(../../../assets/images/banner/bnr5.jpg);
    
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.workingTextContainer{
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}